import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Preloader from "./components/Preloader/Preloader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import EmailSignup from './pages/EmailSignup/EmailSignup';
import FaqPage from './pages/Faq/Faq';
import Search from './pages/Search/Search';

import ReactGA from "react-ga4";
import { dataLayer } from 'react-gtm-module';

const HomePage = React.lazy(() => import("./pages/HomePage"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));
const Treehouse = React.lazy(() => import("./pages/Treehouse/Treehouse"));
const TreehousesSRP = React.lazy(() => import("./pages/TreehousesSRP/TreehousesSRP"));
const ContentfulPage = React.lazy(() => import("./pages/ContentfulPage/ContentfulPage"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const Giftcards = React.lazy(() => import("./pages/GiftCards/GiftCards"));

interface Address {
    '@type': string;
    addressCountry: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
}

interface StarRating {
    '@type': string;
    ratingValue: string;
}

interface StructuredData {
    "@context": string;
    "@type": string;
    headline: string;
    description: string;
    image: string;
    datePublished: string;
    petsAllowed: boolean;
    email: string;
    location: string;
    address: Address;
    name: string;
    telephone: string;
    priceRange: string;
    starRating: StarRating;
}

const App: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    // const [treedwellersStructuredData] = useState<StructuredData>({
    //     "@context": "https://schema.org",
    //     "@type": "Hotel",
    //     name: 'Treedwellers', 
    //     headline: "Extraordinary Treehouse Escapes",
    //     description: "We only build extraordinary treehouses, always with your wellbeing in mind. We know your down time is precious, and we can help you make your experiences in our woodland just right for you.",
    //     image: "https://images.ctfassets.net/8k7hdgd4hd8z/4mvSBynL0TD4IaNYGeWtKC/ed55ca0fe203d585ad31a74f43c74e17/LR-TD-Ladys_Slipper-7.jpg",
    //     datePublished: new Date("2021-09-04T09:25:01.340Z").toISOString(),
    //     petsAllowed: false,
    //     email: 'aloha@treedwellers.co.uk',
    //     location: 'Cornbury, Oxfordshire, UK',
    //     address: {            
    //         "@type": "PostalAddress",
    //         "addressCountry": "UK",
    //         "addressLocality": "Oxfordshire",
    //         "addressRegion": "Chipping Norton",
    //         "postalCode": "OX7 3DF",
    //         "streetAddress": "Treedwellers Cornbury Witney Road"
    //     },        
    //     telephone: '+44 1993 224350',
    //     priceRange: '£232-£320',
    //     starRating: {
    //         "@type": "Rating",
    //         "ratingValue": "4.8"
    //     },
    // });

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize("G-X1SWD6F0VS");


        // Set loading state
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 1000);

        // Cleanup timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        name: 'Treedwellers',
                        headline: "Extraordinary Treehouse Escapes",
                        description: "We only build extraordinary treehouses, always with your wellbeing in mind. We know your down time is precious, and we can help you make your experiences in our woodland just right for you.",
                        image: "https://images.ctfassets.net/8k7hdgd4hd8z/4mvSBynL0TD4IaNYGeWtKC/ed55ca0fe203d585ad31a74f43c74e17/LR-TD-Ladys_Slipper-7.jpg",
                        datePublished: new Date("2021-09-04T09:25:01.340Z").toISOString(),
                        petsAllowed: false,
                        email: 'aloha@treedwellers.co.uk',
                        location: 'Cornbury, Oxfordshire, UK',
                        address: {
                            "@type": "PostalAddress",
                            "addressCountry": "UK",
                            "addressLocality": "Oxfordshire",
                            "addressRegion": "Chipping Norton",
                            "postalCode": "OX7 3DF",
                            "streetAddress": "Treedwellers Cornbury Witney Road"
                        },
                        telephone: '+44 1993 224350',
                        priceRange: '£232-£320',
                        starRating: {
                            "@type": "Rating",
                            "ratingValue": "4.8"
                        },
                    })}
                </script>
                <script async defer
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8673219055070657"
                    crossOrigin="anonymous">
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11389980328"></script> 
            </Helmet>

            <ScrollToTop>
                <Suspense fallback={<Preloader loaded={isLoaded} />}>
                    <Routes>
                        <Route index element={<HomePage />} />
                        <Route path="treehouses" element={<TreehousesSRP />} />
                        <Route path="treehouses/:slug" element={<Treehouse />} />
                        <Route path={'about'} element={<About />} />
                        <Route path={'faq'} element={<FaqPage />} />
                        <Route path={'terms-and-conditions'} element={<ContentfulPage slug={'terms-and-conditions'} />} />
                        <Route path={'privacy-policy'} element={<ContentfulPage slug={'privacy-policy'} />} />
                        <Route path={'events'} element={<ContentfulPage slug={'events'} />} />
                        <Route path={'blog'} element={<ContentfulPage slug={'blog'} />} />
                        <Route path={'blog/:slug'} element={<Blog />} />
                        <Route path={'weddings'} element={<ContentfulPage slug={'weddings'} />} />
                        <Route path={'contact'} element={<Contact slug={'contact'} />} />
                        <Route path={'jobs'} element={<ContentfulPage slug={'jobs'} />} />
                        <Route path={'giftcards'} element={<Giftcards slug={'giftcards'} />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path={'email-signup'} element={<EmailSignup slug={'signup'} />} />
                        <Route path="/search" element={<Search />} />
                    </Routes>
                </Suspense>
            </ScrollToTop>
        </>

        /* <CookieConsent

                acceptOnScroll
                acceptOnScrollPercentage={10}
                buttonText={'I\'m Happy'}
                // debug
                overlay
                style={{
                    background: 'rgb(255 255 255)',
                    zIndex: '999',
                    color: '#272727',
                    width: '360px',
                    fontSize: '1rem',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height:'fit-content',
                    flexDirection:'column',
                    textAlign:'center',
                    padding:'2rem',
                    borderRadius:'2rem',
                    borderTopRightRadius: '0px',
                    borderBottomLeftRadius: '0px'
                }
                }
                contentStyle={ {
                    flex: "1 0 0",
                    width: '100%',
                    margin: 0
                }}
                buttonClasses="btn btn-secondary"
                disableStyles={true}
                overlayStyle={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: "999",
                    backgroundColor: "rgba(0,0,0,0.3)",
                }}
            >
                <IconContext.Provider value={{ size:'10rem',style:{paddingRight:'1rem',color:'#969c7f'}}}>
                    <BiCookie/>
                </IconContext.Provider>
                <h3>Cookie?</h3>
                <p>We use our own cookies, and some third parties to make navigation of our website safe and easy for users.</p>
                <p>You can read more information about in our <Link to={'/privacy-policy'}>Privacy Policy</Link></p>
            </CookieConsent>*/
    );
}


export default App;
