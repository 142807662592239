import React, { useEffect, useState } from 'react';
import styles from './Nav.module.scss';
import TDLogo from "../../assets/td_logo_full_white.svg";
import { Button } from 'reactstrap';
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

interface NavigationProps {
    book?: boolean;
    showLogo?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ book, showLogo: initialShowLogo }) => {
    const showBookBtn = typeof book === 'undefined' ? true : book;
    const defaultShowLogo = typeof initialShowLogo === 'undefined' ? true : initialShowLogo;

    const links = [
        { id: 'nav-home', to: '/', label: 'Home' },
        { id: 'nav-treehouses', to: '/treehouses', label: 'Treehouses' },
        { id: 'nav-about', to: '/about', label: 'About' },
        { id: 'nav-blog', to: '/blog', label: 'Blog' },
        { id: 'nav-weddings', to: '/weddings', label: 'Weddings' },
        { id: 'nav-jobs', to: '/jobs', label: 'Jobs' },
        { id: 'nav-giftcards', to: '/giftcards', label: 'Giftcards' },
        { id: 'nav-contact', to: '/contact', label: 'Contact' },
        { id: 'nav-faq', to: '/faq', label: 'FAQ' }
    ];

    const [show, setShow] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [navClass, setNavClass] = useState(false);
    const [showLogo, setShowLogo] = useState(defaultShowLogo);

    const trackScrolling = () => {
        if (window.scrollY > 150) {
            setNavClass(true);
            setShowLogo(true);
        } else {
            setNavClass(false);
            setShowLogo(defaultShowLogo);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        trackScrolling();

        const timer = setTimeout(() => {
            setShow(true);
        }, 1500);

        return () => {
            document.removeEventListener('scroll', trackScrolling);
            clearTimeout(timer);
        };
    }, [defaultShowLogo]);

    const toggleNav = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    href="../../assets/td_logo_full_white.svg"
                    as="image"
                    type="image/svg+xml"
                />                                                
            </Helmet>
            <header>
                {/* <Fade top when={show}> */}
                    <div className={`${styles.tdNav}${navClass ? ' ' + styles.fixed : ''}`}>
                        {showBookBtn && (
                            <Link to="/treehouses" title="Treehouse Holidays In The Cotswolds">
                                <Button
                                    outline
                                    color="white"
                                    className={styles.btnBook}
                                    size="lg"
                                >
                                    Book Your Escape Today
                                </Button>
                            </Link>
                        )}

                        {showLogo && (
                            <Link to="/"
                            >                                
                                <img
                                    src={TDLogo}
                                    alt="Treedwellers"
                                    className={styles.logo}
                                    height={"166"}
                                    width={"249"}
                                    loading="eager"
                                    fetchPriority="high"
                                />                                
                            </Link>
                        )}
                    </div>
                {/* </Fade> */}
            </header>

            <Fade top when={show}>
                <div
                    className={`${styles.Hamburger} ${menuOpen ? styles.open : 'closed'}`}
                    onClick={toggleNav}
                >
                    <span />
                    <span />
                    <span />
                </div>
            </Fade>

            <menu className={`${styles.MenuOverlay} ${menuOpen ? styles.menuOpen : styles.menuClosed}`}>
                <nav>
                    <ul>
                        {links.map((item) => (
                            <li
                                key={item.id}
                                style={{ height: `calc(100% / ${links.length})` }}
                            >
                                <Link
                                    to={item.to}
                                    onClick={toggleNav}
                                    id={item.id}
                                >
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </menu>
        </>
    );
};

export default Navigation;