import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { contentfulKey, graphQLEndPoint } from '../../graphql/config/config';
import { getFaqPageContent } from '../../graphql/resolvers/faqPage';
import { Container, Row } from 'reactstrap';
import styles from './Faq.module.scss'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

interface FaqProps {
    // Add any props here if needed
}

const Faq: React.FC<FaqProps> = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [content, setContent] = useState<any>();

    const [open, setOpen] = useState('');
    const toggle = (id: string) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    const loading = () => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 1500);
    };

    useEffect(() => {
        loading();
    }, []); // 

    useEffect(() => {
        axios.post(
            graphQLEndPoint,
            {
                query: getFaqPageContent,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: contentfulKey,
                }
            }).then(response => {
                setContent(response.data.data.faqCollection.items[0]);
            })
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 1500);


        return () => clearTimeout(timer);

    }, []);

    console.log(content)

    return (
        <>
            <Container fluid className="container">
                <Row className="align-items-center">
                    <div className={styles.header}>
                        <h1>{content?.pageTitle}</h1>
                        <p>{content?.pageDescription.json.content[0].content[0].value}</p>
                    </div>
                </Row>
                <Container className="container-md">
                    <Accordion flush open={open} toggle={toggle} className={styles.accordion}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">{content?.petsQuestion}</AccordionHeader>
                            <AccordionBody accordionId="1">
                                <p>{content?.petsAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">{content?.kitchenQuestion}</AccordionHeader>
                            <AccordionBody accordionId="2">
                                <p>{content?.kitchenAnswer.json.content[0].content[0].value}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="3">{content?.snacksQuestion}</AccordionHeader>
                            <AccordionBody accordionId="3">
                                <p>{content?.snacksAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="4">{content?.bathroomQuestion}</AccordionHeader>
                            <AccordionBody accordionId="4">
                                <p>{content?.bathroomAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="5">{content?.whatsIncluded}</AccordionHeader>
                            <AccordionBody accordionId="5">
                                <p>{content?.whatsIncludedAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="6">{content?.notIncludedQuestion}</AccordionHeader>
                            <AccordionBody accordionId="6">
                                <p>{content?.notIncludedAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="7">{content?.heatingQuestion}</AccordionHeader>
                            <AccordionBody accordionId="7">
                                <p>{content?.heatingAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="8">{content?.smoking}</AccordionHeader>
                            <AccordionBody accordionId="8">
                                <p>{content?.smokingAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="9">{content?.location}</AccordionHeader>
                            <AccordionBody accordionId="9">                                                        
                                <p>{content?.locationAnswerSubtext}</p>
                                <img
                                    src={content?.locationAnswer?.url}
                                    alt={content?.locationAnswer?.description || "Location map"}
                                    className="img-fluid" // Add this to make the image responsive
                                />  
                            </AccordionBody>
                        </AccordionItem> 
                        <AccordionItem>
                            <AccordionHeader targetId="10">{content?.parkingQuestion}</AccordionHeader>
                            <AccordionBody accordionId="10">
                                <p>{content?.parkingAnswer}</p>                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="11">{content?.checkInQuestion}</AccordionHeader>
                            <AccordionBody accordionId="11">
                                <p>{content?.checkInAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="12">{content?.oneNightQuestion}</AccordionHeader>
                            <AccordionBody accordionId="12">
                                <p>{content?.oneNightAnswer}</p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </Container>
            </Container>

        </>
    );
};

export default Faq;