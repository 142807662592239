import React from 'react';
import { Helmet } from "react-helmet";
import Navigation from "../../components/Nav/Navigation";
import Footer from "../../components/Footer/Footer";
import PageHero from "../../components/PageHero/PageHero";

import hero from '../../assets/office-sculpture.jpg';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row, List } from "reactstrap";
import emailjs from '@emailjs/browser';


const initialState = {
    name: '',
    email: '',
    message: '',
    msgSend: false
}



class Contact extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    loading = () => {
        setTimeout(() => this.setState({
            isLoaded: true,
        }), 1500)
    }

    async componentDidMount() {
        this.loading();
    }

    sendEmail = () => {

        /**
         * basic validation
         */
        if (this.state.name !== '' &&
            this.state.email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
            && this.state.message !== ''
        ) {

            const emailParams = {
                from_name: this.state.name,
                reply_to: this.state.email,
                message: this.state.message
            }

            emailjs.send('service_nz6q22e', 'template_d62f3u7', emailParams, 'zbf8ElRb2tmQyyxyu')
                .then((result) => {
                    //console.log(result.text);
                    this.setState({
                        msgSent: true
                    })
                }, (error) => {
                    console.log(error.text);
                });
        }

    }

    render() {

        return (
            <>
                <Helmet>
                    <title>{'Contact Us | TreeDwellers'}</title>
                    <meta name="description" content={'Get in touch with TreeDwellers – Luxury tree houses at Cornbury, Oxfordshire.  Visit our Contact Page for inquiries, support, and collaboration opportunities. Connect with us today'} />
                    <link rel='canonical' href='https://treedwellers.co.uk/contact' />
                </Helmet>
                <Navigation />
                <PageHero hero={hero} heading={'Contact Us'} />
                <section style={{ backgroundColor: '#c2cad2' }}>
                    <Container className={'my-5 mx-0 container-fluid'}>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <p>If you have any questions about our treehouses, our grounds or anything else, let us know in the box below and we'll be in touch soon!</p>
                                <Form inline>
                                    <FormGroup floating>
                                        <Input type={'text'} name={'name'} onChange={(e) => {
                                            this.setState({ name: e.target.value! })
                                        }} valid={this.state.name !== ''} invalid={this.state.name === ''} />
                                        <Label for={'name'}>
                                            Your Name
                                        </Label>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input type={'text'} name={'email'} onChange={(e) => {
                                            this.setState({ email: e.target.value! })
                                        }} valid={this.state.email.match(
                                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        )}

                                            invalid={!this.state.email.match(
                                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                            )}

                                        />
                                        <Label for={'name'}>
                                            Your Email
                                        </Label>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input type={'textarea'} name={'message'} onChange={(e) => {
                                            this.setState({ message: e.target.value! })
                                        }} valid={this.state.message !== ''} invalid={this.state.message === ''} style={{ height: '300px' }} />
                                        <Label for={'message'}>
                                            Your Message
                                        </Label>
                                    </FormGroup>
                                </Form>

                                {this.state.msgSent ? (
                                    <Alert>Your message has been sent, we'll be in touch with you soon!</Alert>
                                ) : (<Button className={'btn btn-secondary text-right'} onClick={this.sendEmail} style={{ float: 'right' }}>Send!</Button>)}

                            </Col>
                        </Row>
                    </Container>
                    <Container className={'my-5 text-center'}>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <p><small>For Press enquiries please contact Alana Fogarty, <a href={'mailto:Alana@farcommunications.co.uk'}>Alana@farcommunications.co.uk</a></small></p>
                                <br></br>
                                <h4>Office Hours & Contact</h4>
                                <List type="unstyled">
                                    <li>Monday - Saturday: 9am - 7pm</li>
                                    <li>Sunday: 9am - 5pm</li>
                                    <li>Office Number: +44 1993 224350</li>
                                </List>

                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </>
        )

    }
}

export default Contact;
