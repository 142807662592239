import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import Navigation from "../../components/Nav/Navigation";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import { getTreehouses } from "../../graphql/resolvers/treehouses";
import { contentfulKey, graphQLEndPoint } from "../../graphql/config/config";
import { Fade } from "react-reveal";
import hero from '../../assets/forest.jpg';
import PageHero from "../../components/PageHero/PageHero";
import SearchResults from '../../components/SearchResults/SearchResults';


const Search = () => {
    const [structures, setStructures] = useState([]);
    const [layout, setLayout] = useState('list');
    const [show, setShow] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const scroll = () => {
        window.scrollTo(0, window.innerHeight);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(true);
        }, 2000);

        axios.post(
            graphQLEndPoint,
            {
                query: getTreehouses
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: contentfulKey,
                }
            }
        ).then(response => {
            setStructures(response.data.data.treehouseCollection.items);
            setIsLoaded(true);
        });

        return () => clearTimeout(timer);
    }, []);

    const changeOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
        const orderBy = e.target.value;
        let sortedStructures = [...structures];

        switch (orderBy) {
            case 'fromPrice_asc':
                sortedStructures.sort((a: any, b: any) => (a.fromPrice > b.fromPrice) ? 1 : -1);
                break;
            case 'fromPrice_desc':
                sortedStructures.sort((a: any, b: any) => (a.fromPrice > b.fromPrice) ? -1 : 1);
                break;
            case 'guests_asc':
                sortedStructures.sort((a: any, b: any) => (a.guests > b.guests) ? 1 : -1);
                break;
            case 'guests_desc':
                sortedStructures.sort((a: any, b: any) => (a.guests > b.guests) ? -1 : 1);
                break;
            case 'beds_asc':
                sortedStructures.sort((a: any, b: any) => (a.beds > b.beds) ? 1 : -1);
                break;
            case 'beds_desc':
                sortedStructures.sort((a: any, b: any) => (a.beds > b.beds) ? -1 : 1);
                break;
        }

        setStructures(sortedStructures);
    };

    return (
        <>
            <Helmet>
                <title>{'Treehouse Holidays in the Cotswolds | TreeDwellers'}</title>
                <meta name="description" content={'Luxury tree houses at Cornbury Park Estate, Oxfordshire. Unlike any other treehouses in the UK. Our tree house holidays offer forward to nature short breaks at prestigious locations.'} />
                <link rel='canonical' href='https://treedwellers.co.uk/treehouses' />
            </Helmet>
            <Navigation />
            <PageHero hero={hero} heading={'Search Results'} />

            <Container className={'mt-5'}>               
                <Row>                  
                    <Col md={layout === 'grid' ? 6 : 12} className={'mb-5'}>
                        <Fade bottom>
                            {/* <TreeHouseCard treehouse={structure} layout={layout} propKey={index} /> */}
                            <SearchResults structures={structures} />
                        </Fade>
                    </Col>                
                </Row>
            </Container>

            <section className={"section-green dandelion-bg"}>
                <Container className={'text-center'}>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <Fade bottom>
                                <h2 className="text-center mb-5">Forward To Nature<br />Back To You</h2>
                            </Fade>
                        </Col>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <Fade bottom>
                                    <p>Can you imagine a better place to align your vision, prospects and perspective than high up in the trees, deep in the Cotswolds of Oxfordshire? We elevate to liberate & regenerate.</p>
                                    <p>At TreeDwellers, you'll escape the stress of day to day life.</p>
                                    <p>Right now you are likely to be functioning with Beta brain waves - in a state of outwardly focused concentration and high alertness? We aren't designed to maintain that way of being as we do. We'll help you get back to Alpha and Theta, where you are more relaxed, creative and receptive to growth.</p>
                                </Fade>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </section>

            <Footer />
        </>
    );
};

export default Search;