export const getTreehouses = `query getTreehouses {
  treehouseCollection(order: [bookable_DESC,fromPrice_ASC]) {
    items {
      id
      name
      slug
      usp
      searchCardText
      fromPrice
      guests
      beds
      wifi
      image {
        url(transform: {width: 500, quality: 65, format: WEBP})
      }
      imagesCollection {
        items {
          title
          description
          url(transform: {width: 500, quality: 65, format: WEBP})
        }
      }
    }
  }
}`;

export const getTreehouseBySlug = `query getTreehouseBySlug($slug:String!) {

  treehouseCollection(limit: 1, where: {slug: $slug}) {
    items {
      id
      anytimeBookingCategory
      name
      slug
      usp
      searchCardText
      metaTitle
      metaDescription
      fromPrice
      guests
      beds
      wifi
      bookable
      cdcUrl
      calendar
      childPolicy
      petsTitle
      petsPolicyDescription
      description {
        json
      }
      location {
        lat
        lon
      }
      image {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      imagesCollection {
        items {
          title
          description
          url(transform: {width: 1200, quality: 65, format: WEBP})
        }
      }
      checkInTime
      checkInDescription
      checkoutTime
      checkoutDescription
      bedroomImage {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      firstSectionTitle
      firstSectionDescription
      firstSectionParagraph
      firstSectionButtonText
      mapSectionTitle
      mapSectionSubtitle
      mapSectionParagraph
    }
  }
}
`;