import React from 'react';
import styles from './AboutHeroVideo.module.scss';
import video from "../../assets/forest.mp4";

import {Fade} from "react-reveal";
import {Col, Container, Row} from "reactstrap";


const initialState = {
    showText:false,
    showButton:false
}



class AboutHeroVideo extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);
        this.state = initialState;
    }

    loading = () => {
        setTimeout(() => this.setState({
            showText: !this.state.showText,
        }), 1500)
        setTimeout(() => this.setState({
            showButton: !this.state.showButton,
        }), 2500)
    }

    async componentDidMount() {

        this.loading();

    }

    scroll = () => {
        window.scrollTo(0, window.innerHeight);
    }

    render() {

        return(
            <>
                <div className={styles.AboutHeroVideo}>
                    <div className={styles.contentMain}>

                        <section className={styles.headline}>

                            <Container>
                                <Row>
                                    <Col md={{size:8,offset:2}} className={'text-center'}>
                                        <Fade left>
                                            <h3 className={'mb-0'}>We are</h3>
                                        </Fade>
                                        <h1 className="header-tagline pt-0">
                                            <Fade bottom  opposite when={this.state.showText}>TreeDwellers</Fade>
                                        </h1>
                                    </Col>
                                </Row>
                            </Container>

                        </section>

                    </div>
                    <Fade bottom when={this.state.show}>
                        <div className={styles.contentAction} onClick={this.scroll}>
                            <div className={styles.go}>
                                <button className={styles.scroll}><span/></button>
                            </div>
                        </div>
                    </Fade>
                    <div className={styles.videoContainer}>
                        <video loop autoPlay muted playsInline className={styles.video}>
                            <source
                                src={video}
                                type="video/mp4"
                            />
                        </video>
                        <div className={styles.overlay}/>
                    </div>

                </div>

            </>
        )

    }
}

export default AboutHeroVideo;
